<template>
  <b-table-simple
    v-bind="$attrs"
    show-empty
    :class="`sale-items-table font-weight-bold mb-0 ${$attrs.class}`"
    :style="{ minHeight: `${minHeight}px` }"
  >
    <b-thead class="sale-items-thead">
      <b-tr>
        <template v-for="field in fields">
          <b-th
            :key="field.key"
            class="sale-items-th"
            :class="field.class"
            :style="field.thStyle"
          >
            {{ field.label }}
          </b-th>
        </template>
      </b-tr>
    </b-thead>

    <b-tbody class="sale-items-tbody">
      <b-tr v-if="isItemsEmpty">
        <b-td
          colspan="6"
          class="text-center my-auto"
        >
          <div>
            <p class="h4">
              Nenhum Produto
            </p>
          </div>
        </b-td>
      </b-tr>

      <template
        v-for="({ priceRuleSelected: itemPriceData, ...item }, index) in tableItems"
        v-else
      >
        <!-- row com o item principal -->
        <b-tr
          :key="index"
          class="row-item"
        >
          <b-td
            v-if="showNumber"
            class="text-center"
          >
            {{ item.kitItems.length > 0 ? '' : getPositionItem(index) }}
          </b-td>
          <b-td>
            <div class="d-flex align-items-center">
              <b-img
                v-if="showImg"
                :src="item.skuProduct.imageSquare || logoImg"
                width="50"
                height="50"
                alt="imagem"
                class="rounded mr-2"
                style="object-fit: contain;"
              />
              <p class="mb-0">
                {{ `${item.skuProduct.name} ${item.choppGiftCardNumber ? ` (${item.choppGiftCardNumber})` : ''}` }}
              </p>
            </div>
          </b-td>
          <b-td
            class="text-center"
            :class="{
              'edit-cell': enableQuantityEdition && !item.choppGiftCardNumber && !item.id
            }"
            :title="enableQuantityEdition && !item.choppGiftCardNumber && !item.id ? $t('Duplo clique para alterar') : ''"
            @dblclick="showEditAmountCell(index, item)"
          >
            <FormulateForm
              v-if="enableQuantityEdition && index === indexUpdate"
              @submit="updateItem(item)"
            >
              <FormulateInput
                :id="`paybox-item-list-amount-${index}`"
                v-model="formUpdate.quantity"
                name="QTDE."
                type="number"
                validation="required|min:1"
              />
            </FormulateForm>
            <span v-else> {{ item.quantity }} </span>
          </b-td>

          <b-td>
            <div :class="priceTextAlign">
              <template v-if="itemPriceData.priceInfo.localDiscountTotal > 0">
                <span class="sale-items-canceled-price">
                  {{ itemPriceData.priceInfo.unitValue | currency(null, 2) }}
                </span>
                <br>
              </template>

              <template v-else-if="itemPriceData.type === priceRuleTypes.PROMOTION">
                <span class="sale-items-canceled-price">
                  {{ itemPriceData.promotion.priceFrom || itemPriceData.priceInfo.originalPrice | currency(null, 2) }}
                </span>
                <br>
              </template>

              <span> {{ itemPriceData.priceInfo.price | currency(null, 2) }} </span>
            </div>
          </b-td>

          <b-td :class="priceTextAlign">
            <template
              v-if="itemPriceData.type === priceRuleTypes.KIT && itemPriceData.productKit && itemPriceData.productKit.localReturnedKitNetValue > 0"
            >
              <span class="sale-items-canceled-price">
                {{ itemPriceData.priceInfo.localGrossValue | currency(null, 2) }}
              </span>
              <br>
            </template>

            {{ itemPriceData.priceInfo.localNetValue | currency(null, 2) }}
          </b-td>

          <b-td v-if="!itemReadOnly">
            <div
              class="d-flex align-items-center justify-content-center"
              style="gap: 10px"
            >
              <template v-if="index !== indexUpdate">
                <e-button
                  v-if="!hasChoppGiftCardConversion"
                  :id="`paybox-item-list-delete-${index}`"
                  tabindex="-1"
                  size="sm"
                  :variant="item.id ? 'secondary' : 'danger'"
                  icon="trash"
                  @click="removeItem(item)"
                />
              </template>
              <template v-else>
                <e-button
                  :id="`paybox-item-list-amount-confirm-update-${index}`"
                  title="Confirmar"
                  size="sm"
                  variant="primary"
                  icon="check-circle"
                  @click="updateItem(item)"
                />

                <e-button
                  :id="`paybox-item-list-amount-cancel-update-${index}`"
                  title="Cancelar"
                  size="sm"
                  variant="danger"
                  icon="x-circle"
                  @click="cleanUpdate(item)"
                />
              </template>
            </div>
          </b-td>
        </b-tr>

        <!-- row com item do KIT -->
        <template v-if="item.kitItems.length > 0">
          <b-tr
            v-for="({ priceRuleSelected: kitItemPriceData, ...kitItem }, kitIndex) in getKitItems(item.kitItems)"
            :key="`${index}_${kitIndex}`"
            class="row-item"
          >
            <b-td
              v-if="showNumber"
              class="text-center"
            >
              {{
                getPositionItem(index) + (getKitItems(item.kitItems).length - 1) - kitIndex
              }}
            </b-td>
            <b-td style="padding-left: 30px">
              <div class="d-flex align-items-center">
                <p class="mr-1 mb-0">
                  <b-icon icon="arrow-return-right" />
                </p>
                <b-img
                  v-if="showImg"
                  :src="kitItem.skuProduct.imageSquare || logoImg"
                  width="50"
                  height="50"
                  alt="imagem"
                  class="rounded mr-2"
                />
                <p class="mb-0">
                  {{ kitItem.skuProduct.name }}
                </p>
              </div>
            </b-td>
            <b-td class="text-center">
              {{ kitItem.quantity }}
            </b-td>
            <b-td :class="priceTextAlign">
              <template v-if="kitItemPriceData.priceInfo.localDiscountTotal > 0">
                <span class="sale-items-canceled-price">
                  {{ kitItemPriceData.priceInfo.originalPrice | currency(null, 2) }}
                </span>
                <br>
              </template>

              <span> {{ kitItemPriceData.priceInfo.price | currency(null, 2) }} </span>
            </b-td>
            <b-td />
            <b-td v-if="enableQuantityEdition" />
          </b-tr>
        </template>

        <!-- row com item associado (item comodato) -->
        <template v-if="item.lendingProducts">
          <b-tr
            v-for="(lendingItem, lendingIndex) in item.lendingProducts"
            :key="`${index}_${lendingIndex}_${lendingItem.id}`"
            class="row-item"
          >
            <b-td v-if="showNumber" />

            <b-td style="padding-left: 30px">
              <div class="d-flex align-items-center">
                <p class="mr-1 mb-0">
                  <b-icon icon="arrow-return-right" />
                </p>
                <b-img
                  v-if="showImg"
                  :src="lendingItem.skuProduct.imageSquare || logoImg"
                  width="50"
                  height="50"
                  alt="imagem"
                  class="rounded mr-2"
                />
                <p class="mb-0">
                  {{ lendingItem.skuProduct.name }}
                </p>
              </div>
            </b-td>

            <b-td class="text-center">
              {{ lendingItem.quantity }}
            </b-td>

            <b-td :class="priceTextAlign">
              {{ 0 | currency }}
            </b-td>

            <b-td />

            <b-td v-if="enableQuantityEdition" />
          </b-tr>
        </template>
      </template>

      <!-- row de footer (totalizadores) -->
      <template v-if="!isItemsEmpty && showTableFooter">
        <b-tr class="row-footer text-light">
          <b-td v-if="showNumber" />
          <b-td class="text-right">
            Total:
          </b-td>
          <b-td class="text-center">
            {{ resumeData.totalQuantity || 0 }}
          </b-td>
          <b-td class="text-right">
            Total:
          </b-td>
          <b-td :class="priceTextAlign">
            {{ resumeData.total | currency }}
          </b-td>

          <b-td v-if="enableQuantityEdition" />
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import { BTableSimple, BTh, BTd, BTr, BThead, BTbody, BImg } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper, keyboardCode, payBoxUtils } from '@/mixins'
import logoImg from '@/assets/images/logo/echope_small.png'
import { Shortcuts } from 'shortcuts'

const getInitialForm = () => ({
  quantity: 1,
})

export default {
  components: {
    BTableSimple,
    BTh,
    BTd,
    BTr,
    BThead,
    BTbody,
    BImg,
    EButton,
  },

  mixins: [formulateHelper, keyboardCode],

  props: {
    minHeight: {
      type: Number,
      default: 0,
    },

    tableItems: {
      type: Array,
      default: () => [],
    },

    itemReadOnly: {
      type: Boolean,
      default: false,
    },

    pricesToRight: {
      type: Boolean,
      default: false,
    },

    showNumber: {
      type: Boolean,
      default: true,
    },

    showImg: {
      type: Boolean,
      default: false,
    },

    showTableFooter: {
      type: Boolean,
      default: false,
    },

    hasChoppGiftCardConversion: {
      type: Boolean,
      default: false,
    },

    /**
     * {
     *    totalQuantity: number,
     *    total: number
     * }
     */
    resumeData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      indexUpdate: null,
      productUpdate: null,
      formUpdate: getInitialForm(),
      delegateUserData: null,
      logoImg,
      priceRuleTypes: payBoxUtils.priceRuleTypes,
      shortcuts: new Shortcuts(),
    }
  },

  computed: {
    isItemsEmpty() {
      return this.tableItems.length === 0
    },

    priceTextAlign() {
      return `${this.pricesToRight ? 'text-right' : 'text-left'}`
    },

    enableQuantityEdition() {
      return !this.itemReadOnly && !this.hasChoppGiftCardConversion
    },

    fields() {
      return [
        {
          label: this.$t('#'),
          key: 'index',
          class: 'text-center',
          thStyle: { width: '50px' },
          hide: !this.showNumber
        },
        {
          label: this.$t('Nome do Produto'),
          key: 'product',
          class: 'text-left',
        },
        {
          label: this.$t('Quant.'),
          key: 'amount',
          class: 'text-center',
          thStyle: { width: '70px' },
        },
        {
          label: this.$t('Preço un.'),
          key: 'price',
          class: this.priceTextAlign,
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Total'),
          key: 'subtotal',
          class: this.priceTextAlign,
          thStyle: { width: '110px' },
        },
        {
          label: this.$t('Ações'),
          key: 'actions',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.itemReadOnly,
        },
      ].filter(a => !a.hide)
    },
  },

  methods: {
    getPositionItem(index) {
      const calcKitItensLenght = i =>
        (i.kitItems?.length || 0) +
        (i.kitItems?.filter(ki => ki.lendingProducts?.length > 0)
          ?.length || 0)

      const traveledItems = this.tableItems.slice(0, index + 1)
      const traveledItemsKit = traveledItems.filter(i => i.kitItems?.length > 0)
      const totalTraveledItems =
        traveledItemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        traveledItems.length -
        traveledItemsKit.length

      const itemsKit = this.tableItems.filter(i => i.kitItems?.length > 0)
      const totalItems =
        itemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        this.tableItems.length -
        itemsKit.length

      return totalItems - totalTraveledItems + 1
    },

    getKitItems(kitItems) {
      if (!kitItems) return []

      const result = kitItems.flatMap(ki => {
        if (ki.lendingProducts?.length > 0) {
          return [ki, ...(ki.lendingProducts || [])]
        }

        return [ki]
      })

      return result
    },

    async showEditAmountCell(index, item) {
      const showAction = () => {
        this.addUpdateShortcuts()
        this.formUpdate = { quantity: item.quantity }
        this.indexUpdate = index
        setTimeout(() => this.focusInput(`#paybox-item-list-amount-${index}`), 100)
      }

      this.$emit('show-edit-quantity', showAction, item)
    },
    cleanUpdate(item) {
      this.indexUpdate = null
      this.formUpdate = getInitialForm()
      this.shortcuts.reset()

      this.$emit('clean-row-update', item)
    },

    updateItem(item) {
      this.$emit('update-item', item, this.formUpdate, this.cleanUpdate)
    },

    async removeItem(item) {
      this.$emit('remove-item', item)
    },

    addUpdateShortcuts() {
      const { esc } = this.keyCode

      this.shortcuts.add([
        {
          shortcut: esc,
          handler: () => {
            this.cleanUpdate()
            return true
          },
        }
      ])
    }
  },
}
</script>

<style lang="scss" scoped>
.sale-items-table {
  width: 100%;
}

.sale-items-thead {
  height: 50px;

  .sale-items-th {
    background-color: var(--theme-color) !important;
    color: var(--white) !important;
  }
}

.sale-items-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}

.sale-items-tbody {
  &>tr>td {
    padding: 5px 10px;
    font-size: 1rem;
  }

  &>tr.row-item:nth-child(2n) {
    background-color: rgba(var(--theme-color-rgb), 0.15);
  }

  .row-footer {
    background-color: var(--theme-color);

  }

  .edit-cell {
    cursor: pointer;
  }
}

.footer-value-info {
  p {
    line-height: 25px;
  }
}
</style>
