function objectWithoutProperties(obj, exclude) {
  var target = {}
  for (var k in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, k) &&
      exclude.indexOf(k) === -1
    )
      target[k] = obj[k]
  return target
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    _vm._b(
      {
        class: "sale-items-table font-weight-bold mb-0 " + _vm.$attrs.class,
        style: { minHeight: _vm.minHeight + "px" },
        attrs: { "show-empty": "" },
      },
      "b-table-simple",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "b-thead",
        { staticClass: "sale-items-thead" },
        [
          _c(
            "b-tr",
            [
              _vm._l(_vm.fields, function (field) {
                return [
                  _c(
                    "b-th",
                    {
                      key: field.key,
                      staticClass: "sale-items-th",
                      class: field.class,
                      style: field.thStyle,
                    },
                    [_vm._v(" " + _vm._s(field.label) + " ")]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        { staticClass: "sale-items-tbody" },
        [
          _vm.isItemsEmpty
            ? _c(
                "b-tr",
                [
                  _c(
                    "b-td",
                    {
                      staticClass: "text-center my-auto",
                      attrs: { colspan: "6" },
                    },
                    [
                      _c("div", [
                        _c("p", { staticClass: "h4" }, [
                          _vm._v(" Nenhum Produto "),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._l(_vm.tableItems, function (ref, index) {
                var itemPriceData = ref.priceRuleSelected
                var rest = objectWithoutProperties(ref, ["priceRuleSelected"])
                var item = rest
                return [
                  _c(
                    "b-tr",
                    { key: index, staticClass: "row-item" },
                    [
                      _vm.showNumber
                        ? _c("b-td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.kitItems.length > 0
                                    ? ""
                                    : _vm.getPositionItem(index)
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("b-td", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _vm.showImg
                              ? _c("b-img", {
                                  staticClass: "rounded mr-2",
                                  staticStyle: { "object-fit": "contain" },
                                  attrs: {
                                    src:
                                      item.skuProduct.imageSquare ||
                                      _vm.logoImg,
                                    width: "50",
                                    height: "50",
                                    alt: "imagem",
                                  },
                                })
                              : _vm._e(),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.skuProduct.name +
                                      " " +
                                      (item.choppGiftCardNumber
                                        ? " (" + item.choppGiftCardNumber + ")"
                                        : "")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "b-td",
                        {
                          staticClass: "text-center",
                          class: {
                            "edit-cell":
                              _vm.enableQuantityEdition &&
                              !item.choppGiftCardNumber &&
                              !item.id,
                          },
                          attrs: {
                            title:
                              _vm.enableQuantityEdition &&
                              !item.choppGiftCardNumber &&
                              !item.id
                                ? _vm.$t("Duplo clique para alterar")
                                : "",
                          },
                          on: {
                            dblclick: function ($event) {
                              return _vm.showEditAmountCell(index, item)
                            },
                          },
                        },
                        [
                          _vm.enableQuantityEdition && index === _vm.indexUpdate
                            ? _c(
                                "FormulateForm",
                                {
                                  on: {
                                    submit: function ($event) {
                                      return _vm.updateItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "paybox-item-list-amount-" + index,
                                      name: "QTDE.",
                                      type: "number",
                                      validation: "required|min:1",
                                    },
                                    model: {
                                      value: _vm.formUpdate.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formUpdate,
                                          "quantity",
                                          $$v
                                        )
                                      },
                                      expression: "formUpdate.quantity",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(" " + _vm._s(item.quantity) + " "),
                              ]),
                        ],
                        1
                      ),
                      _c("b-td", [
                        _c(
                          "div",
                          { class: _vm.priceTextAlign },
                          [
                            itemPriceData.priceInfo.localDiscountTotal > 0
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sale-items-canceled-price",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              itemPriceData.priceInfo.unitValue,
                                              null,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                ]
                              : itemPriceData.type ===
                                _vm.priceRuleTypes.PROMOTION
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sale-items-canceled-price",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              itemPriceData.promotion
                                                .priceFrom ||
                                                itemPriceData.priceInfo
                                                  .originalPrice,
                                              null,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                ]
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      itemPriceData.priceInfo.price,
                                      null,
                                      2
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "b-td",
                        { class: _vm.priceTextAlign },
                        [
                          itemPriceData.type === _vm.priceRuleTypes.KIT &&
                          itemPriceData.productKit &&
                          itemPriceData.productKit.localReturnedKitNetValue > 0
                            ? [
                                _c(
                                  "span",
                                  { staticClass: "sale-items-canceled-price" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            itemPriceData.priceInfo
                                              .localGrossValue,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                              ]
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  itemPriceData.priceInfo.localNetValue,
                                  null,
                                  2
                                )
                              ) +
                              " "
                          ),
                        ],
                        2
                      ),
                      !_vm.itemReadOnly
                        ? _c("b-td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center",
                                staticStyle: { gap: "10px" },
                              },
                              [
                                index !== _vm.indexUpdate
                                  ? [
                                      !_vm.hasChoppGiftCardConversion
                                        ? _c("e-button", {
                                            attrs: {
                                              id:
                                                "paybox-item-list-delete-" +
                                                index,
                                              tabindex: "-1",
                                              size: "sm",
                                              variant: item.id
                                                ? "secondary"
                                                : "danger",
                                              icon: "trash",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItem(item)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c("e-button", {
                                        attrs: {
                                          id:
                                            "paybox-item-list-amount-confirm-update-" +
                                            index,
                                          title: "Confirmar",
                                          size: "sm",
                                          variant: "primary",
                                          icon: "check-circle",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateItem(item)
                                          },
                                        },
                                      }),
                                      _c("e-button", {
                                        attrs: {
                                          id:
                                            "paybox-item-list-amount-cancel-update-" +
                                            index,
                                          title: "Cancelar",
                                          size: "sm",
                                          variant: "danger",
                                          icon: "x-circle",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cleanUpdate(item)
                                          },
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  item.kitItems.length > 0
                    ? _vm._l(
                        _vm.getKitItems(item.kitItems),
                        function (ref, kitIndex) {
                          var kitItemPriceData = ref.priceRuleSelected
                          var rest = objectWithoutProperties(ref, [
                            "priceRuleSelected",
                          ])
                          var kitItem = rest
                          return _c(
                            "b-tr",
                            {
                              key: index + "_" + kitIndex,
                              staticClass: "row-item",
                            },
                            [
                              _vm.showNumber
                                ? _c("b-td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getPositionItem(index) +
                                            (_vm.getKitItems(item.kitItems)
                                              .length -
                                              1) -
                                            kitIndex
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mr-1 mb-0" },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "arrow-return-right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.showImg
                                        ? _c("b-img", {
                                            staticClass: "rounded mr-2",
                                            attrs: {
                                              src:
                                                kitItem.skuProduct
                                                  .imageSquare || _vm.logoImg,
                                              width: "50",
                                              height: "50",
                                              alt: "imagem",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(kitItem.skuProduct.name) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("b-td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(kitItem.quantity) + " "),
                              ]),
                              _c(
                                "b-td",
                                { class: _vm.priceTextAlign },
                                [
                                  kitItemPriceData.priceInfo
                                    .localDiscountTotal > 0
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "sale-items-canceled-price",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    kitItemPriceData.priceInfo
                                                      .originalPrice,
                                                    null,
                                                    2
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                      ]
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            kitItemPriceData.priceInfo.price,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _c("b-td"),
                              _vm.enableQuantityEdition ? _c("b-td") : _vm._e(),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  item.lendingProducts
                    ? _vm._l(
                        item.lendingProducts,
                        function (lendingItem, lendingIndex) {
                          return _c(
                            "b-tr",
                            {
                              key:
                                index +
                                "_" +
                                lendingIndex +
                                "_" +
                                lendingItem.id,
                              staticClass: "row-item",
                            },
                            [
                              _vm.showNumber ? _c("b-td") : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mr-1 mb-0" },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "arrow-return-right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.showImg
                                        ? _c("b-img", {
                                            staticClass: "rounded mr-2",
                                            attrs: {
                                              src:
                                                lendingItem.skuProduct
                                                  .imageSquare || _vm.logoImg,
                                              width: "50",
                                              height: "50",
                                              alt: "imagem",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              lendingItem.skuProduct.name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("b-td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " + _vm._s(lendingItem.quantity) + " "
                                ),
                              ]),
                              _c("b-td", { class: _vm.priceTextAlign }, [
                                _vm._v(
                                  " " + _vm._s(_vm._f("currency")(0)) + " "
                                ),
                              ]),
                              _c("b-td"),
                              _vm.enableQuantityEdition ? _c("b-td") : _vm._e(),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ]
              }),
          !_vm.isItemsEmpty && _vm.showTableFooter
            ? [
                _c(
                  "b-tr",
                  { staticClass: "row-footer text-light" },
                  [
                    _vm.showNumber ? _c("b-td") : _vm._e(),
                    _c("b-td", { staticClass: "text-right" }, [
                      _vm._v(" Total: "),
                    ]),
                    _c("b-td", { staticClass: "text-center" }, [
                      _vm._v(
                        " " + _vm._s(_vm.resumeData.totalQuantity || 0) + " "
                      ),
                    ]),
                    _c("b-td", { staticClass: "text-right" }, [
                      _vm._v(" Total: "),
                    ]),
                    _c("b-td", { class: _vm.priceTextAlign }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.resumeData.total)) +
                          " "
                      ),
                    ]),
                    _vm.enableQuantityEdition ? _c("b-td") : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }